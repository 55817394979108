<script lang="ts" setup>
const items = [
  {
    label: "Profile",
    slot: "profile",
  },
];
</script>

<template>
  <div>
    <NuxtLayout name="app-layout">
      <template #header>Settings</template>
      <div class="p-4">
        <UTabs :items="items" />
        <div>
          <h2 class="text-xl font-bold">
            <slot name="subheader" />
          </h2>
        </div>
        <slot />
      </div>
    </NuxtLayout>
  </div>
</template>
